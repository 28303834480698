import People from "@mui/icons-material/People";
import Groups from "@mui/icons-material/Groups";
import Store from "@mui/icons-material/Store";
import SupervisorAccount from "@mui/icons-material/SupervisorAccount";
import DesignServices from "@mui/icons-material/DesignServices";
import Diversity3 from "@mui/icons-material/Diversity3";
import LocalPolice from "@mui/icons-material/LocalPolice";
import Article from "@mui/icons-material/Article";
import CalendarToday from "@mui/icons-material/CalendarToday";
import Report from "@mui/icons-material/Report";
import Announcement from "@mui/icons-material/Announcement";
import ReceiptIcon from "@mui/icons-material/Receipt";
import AssignmentIcon from "@mui/icons-material/Assignment";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import DescriptionIcon from "@mui/icons-material/Description";

export const budgetAndFinance = [
  {
    label: "Statement",
    icon: ReceiptIcon,
    link: "/finance",
  },
  {
    label: "Procurement Plan",
    icon: AssignmentIcon,
    link: "/finance-procurement-plan",
  },
];

export const justiceSystem = [
  {
    label: "Blotters",
    icon: Report,
    link: "/justice-system-blotters",
  },
  {
    label: "Complaints",
    icon: Announcement,
    link: "/justice-system-complaints",
  },
];

export const management = [
  {
    label: "Officials",
    icon: SupervisorAccount,
    link: "/officials",
  },
  {
    label: "Constituents",
    icon: People,
    link: "/constituents",
  },
  {
    label: "Committees",
    icon: Groups,
    link: "/committees",
  },
  {
    label: "Business",
    icon: Store,
    link: "/business",
  },
  {
    label: "Projects",
    icon: DesignServices,
    link: "/project-management",
  },
  {
    label: "Calendar",
    icon: CalendarToday,
    link: "/calendar",
  },
  {
    label: "Contracts",
    icon: Article,
    link: "/contracts",
  },
  {
    label: "Meetings",
    icon: Diversity3,
    link: "/min-of-meeting",
  },
  {
    label: "Ordinance",
    icon: LocalPolice,
    link: "/ordinance",
  },
];

export const issuance = [
  {
    label: "Barangay ID",
    icon: RecentActorsIcon,
    link: "/issuance-brgy-id",
  },
  {
    label: "Certificate",
    icon: DescriptionIcon,
    link: "/issuance",
  },
];

export const captainCrumbs = {
  dashboard: [{ label: "Dashboard", url: "/", is_active: true }],
  account: [{ label: "Account", url: "/account", is_active: true }],
  account_personal_info: [
    { label: "Personal Info", url: "/account-personal-info", is_active: true },
  ],
  account_update_password: [
    {
      label: "Update Password",
      url: "/account-update-password",
      is_active: true,
    },
  ],
  account_update_username: [
    {
      label: "Update Username",
      url: "/account-update-username",
      is_active: true,
    },
  ],
  barangay: [{ label: "Barangay Info", url: "/barangay", is_active: true }],
  finance: [
    {
      label: "Finance",
      url: "/finance",
      is_active: true,
    },
  ],
  finance_income_expense_statement: [
    {
      label: "Income and Expense Statement",
      url: "/finance-income-expense-statement",
      is_active: true,
    },
  ],
  finance_statement: [
    {
      label: "Statement",
      url: "/finance-statement",
      is_active: true,
    },
  ],
  finance_income: [
    {
      label: "Income",
      url: "/finance-income",
      is_active: true,
    },
  ],
  finance_expense: [
    {
      label: "Expense",
      url: "/finance-expense",
      is_active: true,
    },
  ],
  finance_national_tax_allotment: [
    {
      label: "National Tax Allotment",
      url: "/finance-national-tax-allotment",
      is_active: true,
    },
  ],
  finance_procurement_plan: [
    {
      label: "Procurement Plan",
      url: "/finance-procurement-plan",
      is_active: true,
    },
  ],
  constituents: [
    {
      label: "Constituents",
      url: "/constituents",
      is_active: true,
    },
  ],
  constituents_search: [
    {
      label: "Constituents Search",
      url: "/constituents-search",
      is_active: true,
    },
  ],
  issuance: [
    {
      label: "Issuance",
      url: "/issuance",
      is_active: true,
    },
  ],
  issuance_brgy_id: [
    {
      label: "Issuance Brgy ID",
      url: "/issuance-brgy-id",
      is_active: true,
    },
  ],
  issuance_residency: [
    {
      label: "Issuance Certificate Residency",
      url: "/issuance-cert-residency",
      is_active: true,
    },
  ],
  issuance_indigency: [
    {
      label: "Issuance Certificate Indigency",
      url: "/issuance-cert-indigency",
      is_active: true,
    },
  ],
  issuance_cert_clear_work: [
    {
      label: "Issuance Certificate Clearance Work",
      url: "/issuance-cert-clear-work",
      is_active: true,
    },
  ],
  issuance_cert_clear_loan: [
    {
      label: "Issuance Certificate Clearance Loan",
      url: "/issuance-cert-clear-loan",
      is_active: true,
    },
  ],
  issuance_cert_clear_others: [
    {
      label: "Issuance Certificate Clearance Others",
      url: "/issuance-cert-clear-others",
      is_active: true,
    },
  ],
  contracts: [
    {
      label: "Contracts",
      url: "/contracts",
      is_active: true,
    },
  ],
  min_of_meeting: [
    {
      label: "Minutes of the Meeting",
      url: "/min-of-meeting",
      is_active: true,
    },
  ],
  ordinance: [
    {
      label: "Ordinance",
      url: "/ordinance",
      is_active: true,
    },
  ],
  officials: [
    {
      label: "Officials",
      url: "/officials",
      is_active: true,
    },
  ],
  business: [
    {
      label: "Business",
      url: "/business",
      is_active: true,
    },
  ],
  committees: [
    {
      label: "Committees",
      url: "/committees",
      is_active: true,
    },
  ],
  justice_system_blotters: [
    {
      label: "Justice System Blotter",
      url: "/justice-system-blotters",
      is_active: true,
    },
  ],
  justice_system_complaints: [
    {
      label: "Justice System Complaints",
      url: "/justice-system-complaints",
      is_active: true,
    },
  ],
  calendar: [
    {
      label: "Calendar",
      url: "/calendar",
      is_active: true,
    },
  ],
  project_management: [
    {
      label: "Project Management",
      url: "/project-management",
      is_active: true,
    },
  ],
  reports: [
    {
      label: "Reports",
      url: "/reports",
      is_active: true,
    },
  ],
};
