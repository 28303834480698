import { AppBar } from "../../constant/theme";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Avatar from "@material-ui/core/Avatar";
import React, { useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import ManageAccounts from "@mui/icons-material/ManageAccounts";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import PasswordIcon from "@mui/icons-material/Password";
import ExitToApp from "@mui/icons-material/ExitToApp";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemAvatar from "@mui/material/ListItemAvatar";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import jwt_decode from "jwt-decode";

const AppBarComAdmin = (props) => {
  const { open, toggleDrawer, barangayName } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const user = jwt_decode(props.auth.token);

  return (
    <>
      <AppBar
        style={{ backgroundColor: "#FFBD59" }}
        color="inherit"
        position="absolute"
        open={open}
      >
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={() => toggleDrawer()}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {barangayName}
          </Typography>

          {/* <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton> */}

          <IconButton
            color="inherit"
            id="captain-positioned-button"
            aria-controls={open ? "captain-nav-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <Avatar src={user?.user_img_file_url}></Avatar>
          </IconButton>
          <Menu
            id="captain-nav-menu"
            aria-labelledby="captain-positioned-button"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
          >
            <MenuItem component={Link} to="/account-personal-info">
              <ListItemAvatar>
                <Avatar
                  alt="Profile Picture"
                  src={user?.user_img_file_url}
                ></Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`${user?.first_name} ${user?.middle_name} ${user?.last_name}`}
                secondary={user?.user_type}
              />
            </MenuItem>
            <Divider />
            <MenuItem component={Link} to="account-personal-info">
              <ListItemIcon>
                <AccountBoxIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Personal Info</ListItemText>
            </MenuItem>
            <MenuItem component={Link} to="account-update-username">
              <ListItemIcon>
                <ManageAccounts fontSize="small" />
              </ListItemIcon>
              <ListItemText>Update Username</ListItemText>
            </MenuItem>
            <MenuItem component={Link} to="account-update-password">
              <ListItemIcon>
                <PasswordIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Update Password</ListItemText>
            </MenuItem>

            <MenuItem component={Link} to="/logout">
              <ListItemIcon>
                <ExitToApp fontSize="small" />
              </ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(AppBarComAdmin);
