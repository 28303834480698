import React, { useEffect, useState } from "react";
import {
  budgetAndFinance,
  justiceSystem,
  management,
  issuance,
} from "../../constant/menus/captain-menu";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Collapse from "@mui/material/Collapse";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountBalance from "@mui/icons-material/AccountBalance";
import FactCheck from "@mui/icons-material/FactCheck";
import Divider from "@mui/material/Divider";
import { Drawer } from "../../constant/theme";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LogoutIcon from "@mui/icons-material/Logout";
import HomeWork from "@mui/icons-material/HomeWork";
import AppsIcon from "@mui/icons-material/Apps";
import Gavel from "@mui/icons-material/Gavel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import detectMob from "../detectMob";

import { connect } from "react-redux";
import jwt_decode from "jwt-decode";

const DrawerCompanyAdmin = (props) => {
  const { open, toggleDrawer } = props;
  const [expandMenu1, setExpandMenu1] = useState(false);
  const [expandMenu2, setExpandMenu2] = useState(false);
  const [expandMenu3, setExpandMenu3] = useState(false);
  const [expandMenu4, setExpandMenu4] = useState(false);

  useEffect(() => {
    if (detectMob()) {
      toggleDrawer();
      setExpandMenu1(false);
      setExpandMenu2(false);
      setExpandMenu3(false);
      setExpandMenu4(false);
    }
  }, []);

  const user = jwt_decode(props.auth.token);

  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          px: [1],
        }}
      >
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
        >
          <img
            src={user?.barangay?.logo_img_file_url}
            width="80px"
            style={{ marginLeft: "15px" }}
          />
        </Typography>
        <IconButton onClick={(e) => toggleDrawer()}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <List component="nav">
        <ListItemButton to={"/"} component={Link}>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemButton>
        <Divider sx={{ my: 1 }} />
        <ListItemButton to={"/barangay"} component={Link}>
          <ListItemIcon>
            <HomeWork />
          </ListItemIcon>
          <ListItemText primary="Barangay Info" />
        </ListItemButton>
        <Divider sx={{ my: 1 }} />
        <ListItemButton onClick={() => setExpandMenu1(!expandMenu1)}>
          <ListItemIcon>
            <AppsIcon />
          </ListItemIcon>
          <ListItemText primary="Management" />
          {!expandMenu1 ? <ExpandMore /> : <ExpandLess />}
        </ListItemButton>
        <Collapse in={expandMenu1} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {management.length ? (
              management.map((item, index) => {
                return (
                  <ListItemButton
                    sx={{ pl: 4 }}
                    to={item.link}
                    component={Link}
                    key={index}
                  >
                    <ListItemIcon>
                      <item.icon />
                    </ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItemButton>
                );
              })
            ) : (
              <span></span>
            )}
          </List>
        </Collapse>
        <Divider sx={{ my: 1 }} />
        <ListItemButton onClick={() => setExpandMenu2(!expandMenu2)}>
          <ListItemIcon>
            <AccountBalance />
          </ListItemIcon>
          <ListItemText primary="Finance" />
          {!expandMenu2 ? <ExpandMore /> : <ExpandLess />}
        </ListItemButton>
        <Collapse in={expandMenu2} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {budgetAndFinance.length ? (
              budgetAndFinance.map((item, index) => {
                return (
                  <ListItemButton
                    sx={{ pl: 4 }}
                    to={item.link}
                    component={Link}
                    key={index}
                  >
                    <ListItemIcon>
                      <item.icon />
                    </ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItemButton>
                );
              })
            ) : (
              <span></span>
            )}
          </List>
        </Collapse>
        <Divider sx={{ my: 1 }} />
        <ListItemButton onClick={() => setExpandMenu3(!expandMenu3)}>
          <ListItemIcon>
            <Gavel />
          </ListItemIcon>
          <ListItemText primary="Justice System" />
          {!expandMenu3 ? <ExpandMore /> : <ExpandLess />}
        </ListItemButton>
        <Collapse in={expandMenu3} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {justiceSystem.length ? (
              justiceSystem.map((item, index) => {
                return (
                  <ListItemButton
                    sx={{ pl: 4 }}
                    to={item.link}
                    component={Link}
                    key={index}
                  >
                    <ListItemIcon>
                      <item.icon />
                    </ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItemButton>
                );
              })
            ) : (
              <span></span>
            )}
          </List>
        </Collapse>
        <Divider sx={{ my: 1 }} />
        <ListItemButton onClick={() => setExpandMenu4(!expandMenu4)}>
          <ListItemIcon>
            <FactCheck />
          </ListItemIcon>
          <ListItemText primary="Issuance" />
          {!expandMenu4 ? <ExpandMore /> : <ExpandLess />}
        </ListItemButton>
        <Collapse in={expandMenu4} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {issuance.length ? (
              issuance.map((item, index) => {
                return (
                  <ListItemButton
                    sx={{ pl: 4 }}
                    to={item.link}
                    component={Link}
                    key={index}
                  >
                    <ListItemIcon>
                      <item.icon />
                    </ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItemButton>
                );
              })
            ) : (
              <span></span>
            )}
          </List>
        </Collapse>
        <Divider sx={{ my: 1 }} />
        <ListItemButton to={"/logout"} component={Link}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Log Out" />
        </ListItemButton>
      </List>
    </Drawer>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(DrawerCompanyAdmin);
