import React from "react";

const AdminDashboard = React.lazy(() =>
  import("../pages/AdminDashboard/AdminDashboard")
);
const ViewBarangays = React.lazy(() =>
  import("../pages/AdminDashboard/BrgyManagement/Barangay/ViewBarangays")
);
const ViewUserAccounts = React.lazy(() =>
  import("../pages/AdminDashboard/BrgyManagement/UserAccounts/ViewUserAccounts")
);
const ViewCalendarEvents = React.lazy(() =>
  import("../pages/AdminDashboard/BrgyManagement/CalendarEvents/ViewCalendar")
);

const AdminRoutes = [
  {
    path: "/",
    exact: true,
    name: "dashboard",
    type: "view",
    component: AdminDashboard,
  },
  {
    path: "/barangay",
    exact: true,
    name: "barangay",
    type: "view",
    component: ViewBarangays,
  },
  {
    path: "/user-accounts",
    exact: true,
    name: "user_accounts",
    type: "view",
    component: ViewUserAccounts,
  },
  {
    path: "/calendar-events",
    exact: true,
    name: "calendar_events",
    type: "view",
    component: ViewCalendarEvents,
  },
];

export default AdminRoutes;
