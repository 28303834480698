import instance from "../config/axios";

export const doLogin = async (username, password) => {
  const url = "/token";
  var res = await instance({
    method: "get",
    url,
    auth: {
      username: username,
      password: password,
    },
  });
  return res;
};
