import GroupIcon from "@mui/icons-material/Group";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import CalendarMonth from "@mui/icons-material/CalendarMonth";

export const brgyManagementMenu = [
  {
    label: "Barangay",
    icon: CorporateFareIcon,
    link: "/barangay",
  },
  {
    label: "User Accounts",
    icon: GroupIcon,
    link: "/user-accounts",
  },
  {
    label: "Calendar Events",
    icon: CalendarMonth,
    link: "/calendar-events",
  },
];

export const adminCrumbs = {
  dashboard: [{ label: "Dashboard", url: "/", is_active: true }],
  barangay: [{ label: "Barangay", url: "/barangay", is_active: true }],
  user_accounts: [
    { label: "User Accounts", url: "/user-accounts", is_active: true },
  ],
  calendar_events: [
    { label: "Calendar Events", url: "/calendar-event", is_active: true },
  ],
};
