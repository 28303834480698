import React from "react";

const ClientDashboard = React.lazy(() =>
  import("../pages/ClientDashboard/ClientDashboard")
);
const ViewAccount = React.lazy(() =>
  import("../pages/ClientDashboard/Account/ViewAccount")
);
const PersonalInfo = React.lazy(() =>
  import("../pages/ClientDashboard/Account/PersonalInfo")
);
const UpdatePassword = React.lazy(() =>
  import("../pages/ClientDashboard/Account/UpdatePassword")
);
const UpdateUsername = React.lazy(() =>
  import("../pages/ClientDashboard/Account/UpdateUsername")
);
const BarangayInfo = React.lazy(() =>
  import("../pages/ClientDashboard/BarangayInfo/BarangayInfo")
);
const ViewFinance = React.lazy(() =>
  import("../pages/ClientDashboard/Finance/Finance")
);
const ViewStatement = React.lazy(() =>
  import("../pages/ClientDashboard/Finance/Statement/ViewStatement")
);
const ViewIncome = React.lazy(() =>
  import("../pages/ClientDashboard/Finance/Income/ViewIncome")
);
const ViewExpense = React.lazy(() =>
  import("../pages/ClientDashboard/Finance/Expense/ViewExpense")
);
const ViewProcurementPlan = React.lazy(() =>
  import("../pages/ClientDashboard/Finance/ProcurementPlan/ViewProcurementPlan")
);
const ViewIssuance = React.lazy(() =>
  import("../pages/ClientDashboard/Issuance/ViewIssuance")
);
const ViewBrgyID = React.lazy(() =>
  import("../pages/ClientDashboard/Issuance/ViewBrgyID")
);
const ViewCertResidency = React.lazy(() =>
  import("../pages/ClientDashboard/Issuance/CertResidency")
);
const ViewCertIndigency = React.lazy(() =>
  import("../pages/ClientDashboard/Issuance/CertIndigency")
);

const ViewCertClearanceWork = React.lazy(() =>
  import("../pages/ClientDashboard/Issuance/CertClearanceWork")
);
const ViewCertClearanceLoan = React.lazy(() =>
  import("../pages/ClientDashboard/Issuance/CertClearanceLoan")
);
const ViewCertClearanceOthers = React.lazy(() =>
  import("../pages/ClientDashboard/Issuance/CertClearanceOthers")
);
const ViewContracts = React.lazy(() =>
  import("../pages/ClientDashboard/Contracts/ViewContracts")
);
const ViewMinOfMeeting = React.lazy(() =>
  import("../pages/ClientDashboard/MinOfMeetings/ViewMinOfMeeting")
);
const ViewOrdinances = React.lazy(() =>
  import("../pages/ClientDashboard/Ordinances/ViewOrdinances")
);
const ViewConstituents = React.lazy(() =>
  import("../pages/ClientDashboard/Constituents/ViewConstituents")
);
const ConstituentSearch = React.lazy(() =>
  import(
    "../pages/ClientDashboard/Constituents/ConstituentSearch/ConstituentSearch"
  )
);
const ViewBusinesses = React.lazy(() =>
  import("../pages/ClientDashboard/Business/ViewBusinesses")
);
const ViewCommittees = React.lazy(() =>
  import("../pages/ClientDashboard/Committees/ViewCommittees")
);
const ViewOfficials = React.lazy(() =>
  import("../pages/ClientDashboard/Officials/ViewOfficials")
);
const ViewBlotters = React.lazy(() =>
  import("../pages/ClientDashboard/JusticeSystem/Blotter/ViewBlotters")
);
const ViewComplaints = React.lazy(() =>
  import("../pages/ClientDashboard/JusticeSystem/Complaints/ViewComplaints")
);
const ViewCalendar = React.lazy(() =>
  import("../pages/ClientDashboard/Calendar/ViewCalendar")
);
const ViewProjectManagement = React.lazy(() =>
  import("../pages/ClientDashboard/ProjectManagement/ViewProjectManagement")
);
const ViewReports = React.lazy(() =>
  import("../pages/ClientDashboard/Reports/ViewReports")
);

const CaptainRoutes = [
  {
    path: "/",
    exact: true,
    name: "dashboard",
    type: "view",
    component: ClientDashboard,
  },
  {
    path: "/account",
    exact: true,
    name: "account",
    type: "view",
    component: ViewAccount,
  },
  {
    path: "/account-personal-info",
    exact: true,
    name: "account_personal_info",
    type: "view",
    component: PersonalInfo,
  },
  {
    path: "/account-update-password",
    exact: true,
    name: "account_update_password",
    type: "view",
    component: UpdatePassword,
  },
  {
    path: "/account-update-username",
    exact: true,
    name: "account_update_username",
    type: "view",
    component: UpdateUsername,
  },
  {
    path: "/barangay",
    exact: true,
    name: "barangay",
    type: "view",
    component: BarangayInfo,
  },
  {
    path: "/finance",
    exact: true,
    name: "finance",
    type: "view",
    component: ViewFinance,
  },
  {
    path: "/finance-statement",
    exact: true,
    name: "finance_statement",
    type: "view",
    component: ViewStatement,
  },
  {
    path: "/finance-income",
    exact: true,
    name: "finance_income",
    type: "view",
    component: ViewIncome,
  },
  {
    path: "/finance-expense",
    exact: true,
    name: "finance_expense",
    type: "view",
    component: ViewExpense,
  },
  {
    path: "/finance-procurement-plan",
    exact: true,
    name: "finance_procurement_plan",
    type: "view",
    component: ViewProcurementPlan,
  },
  {
    path: "/constituents",
    exact: true,
    name: "constituents",
    type: "view",
    component: ViewConstituents,
  },
  {
    path: "/constituents-search",
    exact: true,
    name: "constituents_search",
    type: "view",
    component: ConstituentSearch,
  },
  {
    path: "/issuance",
    exact: true,
    name: "issuance",
    type: "view",
    component: ViewIssuance,
  },
  {
    path: "/issuance-brgy-id",
    exact: true,
    name: "issuance_brgy_id",
    type: "view",
    component: ViewBrgyID,
  },
  {
    path: "/issuance-cert-residency",
    exact: true,
    name: "issuance_residency",
    type: "view",
    component: ViewCertResidency,
  },
  {
    path: "/issuance-cert-indigency",
    exact: true,
    name: "issuance_indigency",
    type: "view",
    component: ViewCertIndigency,
  },
  {
    path: "/issuance-cert-clear-work",
    exact: true,
    name: "issuance_cert_clear_work",
    type: "view",
    component: ViewCertClearanceWork,
  },
  {
    path: "/issuance-cert-clear-loan",
    exact: true,
    name: "issuance_cert_clear_loan",
    type: "view",
    component: ViewCertClearanceLoan,
  },
  {
    path: "/issuance-cert-clear-others",
    exact: true,
    name: "issuance_cert_clear_others",
    type: "view",
    component: ViewCertClearanceOthers,
  },
  {
    path: "/contracts",
    exact: true,
    name: "contracts",
    type: "view",
    component: ViewContracts,
  },
  {
    path: "/min-of-meeting",
    exact: true,
    name: "min_of_meeting",
    type: "view",
    component: ViewMinOfMeeting,
  },
  {
    path: "/ordinance",
    exact: true,
    name: "ordinance",
    type: "view",
    component: ViewOrdinances,
  },
  {
    path: "/business",
    exact: true,
    name: "business",
    type: "view",
    component: ViewBusinesses,
  },
  {
    path: "/committees",
    exact: true,
    name: "committees",
    type: "view",
    component: ViewCommittees,
  },
  {
    path: "/officials",
    exact: true,
    name: "officials",
    type: "view",
    component: ViewOfficials,
  },
  {
    path: "/justice-system-blotters",
    exact: true,
    name: "justice_system_blotters",
    type: "view",
    component: ViewBlotters,
  },
  {
    path: "/justice-system-complaints",
    exact: true,
    name: "justice_system_complaints",
    type: "view",
    component: ViewComplaints,
  },
  {
    path: "/calendar",
    exact: true,
    name: "calendar",
    type: "view",
    component: ViewCalendar,
  },
  {
    path: "/project-management",
    exact: true,
    name: "project_management",
    type: "view",
    component: ViewProjectManagement,
  },
  {
    path: "/reports",
    exact: true,
    name: "reports",
    type: "view",
    component: ViewReports,
  },
];

export default CaptainRoutes;
